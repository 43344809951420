var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Text Classes",
        "code-toggler": "",
        "no-shadow": "",
        "card-border": "",
      },
    },
    [
      _c("span", [
        _vm._v("You can use "),
        _c("code", [_vm._v("text-{color}")]),
        _vm._v(" for applying default colors to text."),
      ]),
      _c("br"),
      _c("span", [
        _vm._v("There is also other tailwind classes like "),
        _c("code", [_vm._v("text-white")]),
        _vm._v(", "),
        _c("code", [_vm._v("text-black")]),
        _vm._v(", "),
        _c("code", [_vm._v("text-transparent")]),
      ]),
      _c("div", { staticClass: "demo-alignment" }, [
        _c("span", { staticClass: "text-primary" }, [_vm._v("primary")]),
        _c("span", { staticClass: "text-success" }, [_vm._v("success")]),
        _c("span", { staticClass: "text-danger" }, [_vm._v("danger")]),
        _c("span", { staticClass: "text-warning" }, [_vm._v("warning")]),
        _c("span", { staticClass: "text-dark" }, [_vm._v("dark")]),
        _c("span", { staticClass: "text-white bg-primary p-2 rounded" }, [
          _vm._v("white"),
        ]),
        _c("span", { staticClass: "text-black" }, [_vm._v("black")]),
        _c("span", { staticClass: "text-transparent" }, [
          _vm._v("transparent"),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n\n<div class="demo-alignment">\n  <span class="text-primary">primary</span>\n  <span class="text-success">success</span>\n  <span class="text-danger">danger</span>\n  <span class="text-warning">warning</span>\n  <span class="text-dark">dark</span>\n  <span class="text-white op-block">white</span>\n  <span class="text-black">black</span>\n  <span class="text-transparent op-block">transparent</span>\n</div>\n\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }