var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "colors-demo" } },
    [
      _c("colors-default"),
      _c("colors-gradient"),
      _c("colors-customization"),
      _c("colors-change-color"),
      _c("colors-change-color-SSR"),
      _c("colors-tailwind"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }