var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default Colors", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "We have a series of colors that are used by default. They include:"
        ),
      ]),
      _c("vx-list", { attrs: { list: _vm.defaultColors } }),
      _c("p", { staticClass: "mb-5" }, [
        _vm._v(
          "For each color, we think has its functionality in the application as alerts of errors ("
        ),
        _c("strong", [_vm._v("danger")]),
        _vm._v("), warnings to the user ("),
        _c("strong", [_vm._v("warning")]),
        _vm._v("), communications of approval ("),
        _c("strong", [_vm._v("success")]),
        _vm._v("), or main color of the application ("),
        _c("strong", [_vm._v("primary")]),
        _vm._v(")."),
      ]),
      _c("ul", { staticClass: "demo-alignment text-white" }, [
        _c("li", { staticClass: "bg-primary" }, [
          _vm._v("\n                primary\n            "),
        ]),
        _c("li", { staticClass: "bg-success" }, [
          _vm._v("\n                success\n            "),
        ]),
        _c("li", { staticClass: "bg-danger" }, [
          _vm._v("\n                danger\n            "),
        ]),
        _c("li", { staticClass: "bg-warning" }, [
          _vm._v("\n                warning\n            "),
        ]),
        _c("li", { staticClass: "bg-dark" }, [
          _vm._v("\n                dark\n            "),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<ul class="demo-alignment text-white">\n  <li class="bg-primary">primary</li>\n  <li class="bg-success">success</li>\n  <li class="bg-danger">danger</li>\n  <li class="bg-warning">warning</li>\n  <li class="bg-dark">dark</li>\n</ul>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }