var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Change Color SSR" } },
    [
      _c("p", [
        _vm._v(
          "To change the main colors in SSR (Server-Side Rendering) for example if you are using nuxt.js the main variables of stylus and those of css"
        ),
      ]),
      _c("p", [
        _vm._v(
          "To change the variables we must first import them into a .styl file and change the values of the variables by the colors required"
        ),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "my-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("p", { staticClass: "mb-2" }, [
            _vm._v(
              "In order to change the variables and use them you have to have implemented the stylus loaders, to implement them by npm would be something like this"
            ),
          ]),
          _c("code", [_vm._v("npm i stylus stylus-loader --save-dev")]),
        ]
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("p", [
            _vm._v("The value of the colors can only be "),
            _c("code", [_vm._v("RGB")]),
            _vm._v(
              " and nothing else is added to the number without the letters "
            ),
            _c("code", [_vm._v("rgb")]),
            _vm._v(" or the parentheses"),
          ]),
          _c("p", [
            _vm._v("For example if the color is "),
            _c("code", [_vm._v("rgb(50,100,200)")]),
            _vm._v(" the value to be implemented is only: "),
            _c("code", [_vm._v("50,100,A")]),
          ]),
        ]
      ),
      _c("br"),
      _c("prism", { staticClass: "rounded-lg", attrs: { language: "js" } }, [
        _vm._v(
          "\n// main.styl\n\n@import '~vuesax/dist/styles/index.styl'\n\n$vs-primary = 50,100,200\n\n:root {\n  --vs-primary: $vs-primary\n}\n        "
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-base" },
        [
          _c("p", [_vm._v("The stylus variables of the main colors are:")]),
          _c("prism", { staticClass: "rounded-lg" }, [
            _vm._v(
              "\n$vs-primary\n$vs-success\n$vs-danger\n$vs-warning\n$vs-dark\n            "
            ),
          ]),
          _c("p", { staticClass: "mt-base" }, [
            _vm._v("And the css variables are:"),
          ]),
          _c(
            "prism",
            { staticClass: "rounded-lg", attrs: { language: "css" } },
            [
              _vm._v(
                "\n--vs-primary\n--vs-success\n--vs-danger\n--vs-warning\n--vs-dark\n            "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }