var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Customize Theme Colors",
        "code-toggler": "",
        "code-language": "js",
      },
    },
    [
      _c("p", [
        _vm._v(
          "We almost always need a personalized application with own colors. In Vuesax, you can change the main colors to those that you need and the branding that your application has."
        ),
      ]),
      _c("p", [
        _vm._v("You can change default colors using "),
        _c("code", [_vm._v("themeColors.js")]),
        _vm._v(" file. You can find this file in src folder."),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c("br"),
      _c("ul", { staticClass: "demo-alignment text-white" }, [
        _c("li", { staticStyle: { "background-color": "#5b3cc4" } }, [
          _vm._v("\n                primary\n            "),
        ]),
        _c("li", { staticStyle: { "background-color": "rgb(23, 201, 100)" } }, [
          _vm._v("\n                success\n            "),
        ]),
        _c("li", { staticStyle: { "background-color": "rgb(242, 19, 93)" } }, [
          _vm._v("\n                danger\n            "),
        ]),
        _c("li", { staticStyle: { "background-color": "rgb(255, 130, 0)" } }, [
          _vm._v("\n                warning\n            "),
        ]),
        _c("li", { staticStyle: { "background-color": "rgb(36, 33, 69)" } }, [
          _vm._v("\n                dark\n            "),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\nVue.use(Vuesax, {\n  theme: {\n    colors: {\n      primary: '#5b3cc4',\n      success: 'rgb(23, 201, 100)',\n      danger: 'rgb(242, 19, 93)',\n      warning: 'rgb(255, 130, 0)',\n      dark: 'rgb(36, 33, 69)'\n    }\n  }\n})\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }