var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Gradient Colors", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("We have make gradient color based on our default color:"),
      ]),
      _c("vx-list", { attrs: { list: _vm.defaultColors } }),
      _c("p", { staticClass: "mb-5" }, [
        _vm._v(
          "For each color, we think has its functionality in the application as alerts of errors ("
        ),
        _c("strong", [_vm._v("danger")]),
        _vm._v("), warnings to the user ("),
        _c("strong", [_vm._v("warning")]),
        _vm._v("), communications of approval ("),
        _c("strong", [_vm._v("success")]),
        _vm._v("), or main color of the application ("),
        _c("strong", [_vm._v("primary")]),
        _vm._v(")."),
      ]),
      _c("ul", { staticClass: "demo-alignment text-white" }, [
        _c("li", { staticClass: "bg-primary-gradient text-center" }, [
          _vm._v("\n                primary gradient\n            "),
        ]),
        _c("li", { staticClass: "bg-success-gradient text-center" }, [
          _vm._v("\n                success gradient\n            "),
        ]),
        _c("li", { staticClass: "bg-danger-gradient text-center" }, [
          _vm._v("\n                danger gradient\n            "),
        ]),
        _c("li", { staticClass: "bg-warning-gradient text-center" }, [
          _vm._v("\n                warning gradient\n            "),
        ]),
        _c("li", { staticClass: "bg-dark-gradient text-center" }, [
          _vm._v("\n                dark "),
          _c("br"),
          _vm._v(" gradient\n            "),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<ul class="demo-alignment text-white">\n  <li class="bg-primary-gradient">primary gradient</li>\n  <li class="bg-success-gradient">success gradient</li>\n  <li class="bg-danger-gradient">danger gradient</li>\n  <li class="bg-warning-gradient">warning gradient</li>\n  <li class="bg-dark-gradient">dark gradient</li>\n</ul>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }