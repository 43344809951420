var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { attrs: { title: "Tailwind Colors" } }, [
    _c("span", [
      _vm._v("Tailwind creates "),
      _c("code", [_vm._v("text-{color}")]),
      _vm._v(" and "),
      _c("code", [_vm._v("bg-{color}")]),
      _vm._v(" for text color and background color."),
    ]),
    _c(
      "div",
      { staticClass: "mt-5" },
      [
        _c("tailwind-text-classes"),
        _c("tailwind-background-classes"),
        _c("tailwind-add-more-colors"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }