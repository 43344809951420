var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title: "Background Classes",
        "code-toggler": "",
        "no-shadow": "",
        "card-border": "",
      },
    },
    [
      _c("span", [
        _vm._v("You can use "),
        _c("code", [_vm._v("bg-{color}")]),
        _vm._v(" for applying default colors to background."),
      ]),
      _c("br"),
      _c("span", [
        _vm._v("There is also other tailwind classes like "),
        _c("code", [_vm._v("bg-white")]),
        _vm._v(", "),
        _c("code", [_vm._v("bg-black")]),
        _vm._v(", "),
        _c("code", [_vm._v("bg-transparent")]),
      ]),
      _c("ul", { staticClass: "demo-alignment text-white mt-5" }, [
        _c("li", { staticClass: "bg-primary" }, [_vm._v("primary")]),
        _c("li", { staticClass: "bg-success" }, [_vm._v("success")]),
        _c("li", { staticClass: "bg-danger" }, [_vm._v("danger")]),
        _c("li", { staticClass: "bg-warning" }, [_vm._v("warning")]),
        _c("li", { staticClass: "bg-dark" }, [_vm._v("dark")]),
        _c("li", { staticClass: "bg-white text-dark" }, [_vm._v("white")]),
        _c("li", { staticClass: "bg-black" }, [_vm._v("black")]),
        _c("li", { staticClass: "bg-transparent text-dark" }, [
          _vm._v("transparent"),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n\n<ul class="demo-alignment text-white">\n  <li class="bg-primary">primary</li>\n  <li class="bg-success">success</li>\n  <li class="bg-danger">danger</li>\n  <li class="bg-warning">warning</li>\n  <li class="bg-dark">dark</li>\n  <li class="bg-white text-dark">white</li>\n  <li class="bg-black">black</li>\n  <li class="bg-transparent text-dark">transparent</li>\n</ul>\n\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }