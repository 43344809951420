var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Add more colors", "no-shadow": "", "card-border": "" } },
    [
      _c("p", [
        _vm._v("You can add more colors in "),
        _c("code", [_vm._v("tailwind.js")]),
        _vm._v(" file. You can find this file in root folder."),
      ]),
      _c("span", { staticClass: "mb-5" }, [
        _vm._v("Checkout Color Customization in "),
        _c(
          "a",
          {
            attrs: {
              href: "https://tailwindcss.com/docs/text-color#customizing",
              target: "_blank",
            },
          },
          [_vm._v("Tailwind")]
        ),
      ]),
      _c("prism", { staticClass: "rounded-lg", attrs: { language: "js" } }, [
        _vm._v(
          "\ncolors: {\n\n  ....\n  'secondary': '#767c82',\n  ....\n\n}\n        "
        ),
      ]),
      _c("template", { slot: "codeContainer" }),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("span", [
            _vm._v("After registering color you can use "),
            _c("code", [_vm._v("text-secondary")]),
            _vm._v(" and "),
            _c("code", [_vm._v("bg-secondary")]),
            _vm._v(" in theme"),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }