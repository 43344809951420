var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Change Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the color of the application in process of execution with the function $vs.theme which as a parameter requires a json with the colors to change"
        ),
      ]),
      _c("p", [
        _vm._v(
          "Imagine that in the middle of the application we want the primary color change would be something like this:"
        ),
      ]),
      _c("prism", { staticClass: "rounded-lg", attrs: { language: "js" } }, [
        _vm._v(
          "\nthis.$vs.theme({\n  primary:'rgb(5, 173, 88)' // my new color\n})\n        "
        ),
      ]),
      _c("p", { staticClass: "mt-5" }, [
        _vm._v("By doing this, all "),
        _c("strong", [_vm._v("Vuesax")]),
        _vm._v(
          " components and other components which uses primary color in the application will change. Select one of the colors to change it and that all the components of this application will have that color."
        ),
      ]),
      _c("ul", { staticClass: "demo-alignment text-white" }, [
        _c("li", { staticClass: "bg-primary" }, [
          _c("input", {
            staticClass: "absolute w-full h-full cursor-pointer opacity-0",
            attrs: { type: "color", name: "", value: "" },
            on: {
              change: function ($event) {
                return _vm.changeColor($event.target.value, "primary")
              },
            },
          }),
          _vm._v("\n                primary\n            "),
        ]),
        _c("li", { staticClass: "bg-success" }, [
          _c("input", {
            staticClass: "absolute w-full h-full cursor-pointer opacity-0",
            attrs: { type: "color", name: "", value: "" },
            on: {
              change: function ($event) {
                return _vm.changeColor($event.target.value, "success")
              },
            },
          }),
          _vm._v("\n                success\n            "),
        ]),
        _c("li", { staticClass: "bg-danger" }, [
          _c("input", {
            staticClass: "absolute w-full h-full cursor-pointer opacity-0",
            attrs: { type: "color", name: "", value: "" },
            on: {
              change: function ($event) {
                return _vm.changeColor($event.target.value, "danger")
              },
            },
          }),
          _vm._v("\n                danger\n            "),
        ]),
        _c("li", { staticClass: "bg-warning" }, [
          _c("input", {
            staticClass: "absolute w-full h-full cursor-pointer opacity-0",
            attrs: { type: "color", name: "", value: "" },
            on: {
              change: function ($event) {
                return _vm.changeColor($event.target.value, "warning")
              },
            },
          }),
          _vm._v("\n                warning\n            "),
        ]),
        _c("li", { staticClass: "bg-dark" }, [
          _c("input", {
            staticClass: "absolute w-full h-full cursor-pointer opacity-0",
            attrs: { type: "color", name: "", value: "" },
            on: {
              change: function ($event) {
                return _vm.changeColor($event.target.value, "dark")
              },
            },
          }),
          _vm._v("\n                dark\n            "),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <ul class="demo-alignment text-white">\n    <li class="colorprimaryx">\n      <input @change="changeColor($event.target.value,\'primary\')" type="color" name="" value="">\n      primary\n    </li>\n    <li class="colorsuccessx">\n      <input @change="changeColor($event.target.value,\'success\')" type="color" name="" value="">\n      success\n    </li>\n    <li class="colordangerx">\n      <input @change="changeColor($event.target.value,\'danger\')" type="color" name="" value="">\n      danger\n    </li>\n    <li class="colorwarningx">\n      <input @change="changeColor($event.target.value,\'warning\')" type="color" name="" value="">\n      warning\n    </li>\n    <li class="colordarkx">\n      <input @change="changeColor($event.target.value,\'dark\')" type="color" name="" value="">\n      dark\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  methods: {\n    changeColor(colorx, whatColor) {\n      this.$vs.theme({\n        [whatColor]: colorx\n      })\n    }\n  }\n}\n</script>\n\n<style lang="css" scoped>\n.colorprimaryx {\n  background: rgb(var(--vs-primary)) !important;\n}\n\n.colorsuccessx {\n  background: rgb(var(--vs-success)) !important;\n}\n\n.colordangerx {\n  background: rgb(var(--vs-danger)) !important;\n}\n\n.colorwarningx {\n  background: rgb(var(--vs-warning)) !important;\n}\n\n.colordarkx {\n  background: rgb(var(--vs-dark)) !important;\n}\n</style>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }